$background: #dedede;
$white: white;
$primary: #484258;
$secondary:#34C85F;
$grey-1: #dad8d8;
$grey-2: #ededed;
$grey-3: #353535;
$blue-1: #e9f1ff;
$blue-2: #65B7CC;
$red-1: #e65452;
$orange-1: #f7ae40;