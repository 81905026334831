.info-card__padding {
  padding: 5px;
}
.info-card {
  position: relative;
  background-color: $white;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex: 1;
  min-width: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  box-shadow: $box-shadow;
  border-radius: 2px;

  @include respond-to(gt-sm) {
    padding: 42px;
  }

  .info-card__image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-color: white;
    border: 4px solid $white;
    margin-bottom: 42px;
    margin-top: 42px;
    box-shadow: $box-shadow;

    img {
      max-width: 100%;
    }
  }

  .info-card__content {
    width: 100%;
    margin-left: 10%;
    margin-bottom: 50px;

    @include respond-to(gt-sm) {
      width: 50%;
    }
    .content-header {
      font-family: "futura";

      font-weight: 600;
      font-size: 36pt;
      color: $primary;
      margin-bottom: 4px;
    }
    .content-field{
      padding: 6px;
      font-style: italic;
      font-size: 10pt;
      margin-bottom: 8px;
    }
    .content-bio{
      padding: 6px;
      margin-bottom: 16px;
    }
    .contact-info{
      padding: 6px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: flex-start;

      .contact-info__email{
        margin: 8px 16px 0px 0px;
        margin-left: 0;
        display: flex;
        align-items: center;
      }
      
      .icon {
        color: $secondary;
        margin-right: 4px;
      }
    }
  }

  .info-card__footer {
    position: absolute;
    bottom: 10px;
    right: 10px;

    .button {
      color: $secondary;
      text-decoration: none;
      font-size: 12pt;
    }
  }

}

