.main {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;

  .project-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    max-width: 1200px;
    padding: 5px;
    padding-top: 0px;
  }

  .footer {
    width: 100%;
    height: 100px;
    margin-top: 50px;
    background-color: $primary;
  }
}