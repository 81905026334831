.banner {
  background: url(images/desk.jpg) no-repeat center center; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  
  /* Set a specific height */
  height: 35vh;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;

  @include respond-to(gt-xs) {
    height: 40vh;
  }

  @include respond-to(gt-sm) {
    height: 50vh;
  }

  .layer {
    background-color: rgba(197, 191, 255, 0.2);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .logo {
    z-index: 10;
    width: 80vw;
    max-width: 700px;
  }
}
