@import "./Constants/scss/_constants";

@import "./Components/Main/main";
@import "./Components/InfoCard/info-card";
@import "./Components/ProjectCard/project-card";
@import "./Components/Banner/banner";

@import "./Components/DonutChart/donut-chart";

body {
  overflow-x: hidden;
  background-color: $background;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    font-family: 'Arial';
    letter-spacing: 0.05ch;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.noSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0); 

}
