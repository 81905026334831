.project-card {
    width: 100%;
    height: 370px;
    border-radius: 2px !important;
    margin-bottom: 10px;
    position: relative;

    @include respond-to(sm) {
        width: calc(50% - 5px);
        margin-right: 5px;

        &:nth-child(2n+1) {
            margin-left: 2px;
        }
        &:nth-child(2n+2) {
          margin-right: 0px;
        }
    }

    @include respond-to(gt-sm) {
        width: calc(33% - 10px);
        margin-right: 10px;
        max-width: 500px;

        &:nth-child(3n+1) {
            margin-left: 10px;
        }
        &:nth-child(3n+3) {
          margin-right: 0px;
        }
    }

    .project-card__image div {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        height: 200px;

        a {
            position: absolute;
            margin: auto;
            width: 100%;
            height: 200px;
        }
    }

    .banner {
        height: 100%;
        .logo {
            width: 80%;
        }
    }
  
    .hubot-assistant {
        background-color: $orange-1;
        img { height: 100px; }
    }

    .hubot-analytics {
        background-color: $orange-1;
        img { height: 100px; }
    }
  
    .poker {
        background-color: $blue-2;
        img { height: 200px; }
    }

    .event-app {
        background-color: $white;
        img { 
            height: 200px; 
            width: 100%;
        }
    }
    
    .project-card__links {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        transition: all .2s ease-in-out;

        a {
            padding: 8px;
            img {
                height: 25px;
                &:hover {
                    transform: scale(1.1);
                }
            }
        }
    }

    .project-card__title {
        margin-right: auto;
        margin-left: 20px;
        font-family: "futura";
        color: $primary;
        font-weight: bold;
    }
    .project-card__description {
        margin: 0 20px;
    }

    .project-card__topics {
        position: absolute;
        bottom: 4px;
        left: 4px;

        .topics-chip {
            background-color: $white;
            color: $secondary;
            border-color: $secondary;
            border-radius: 2px;
            margin: 2px;
        }
    }
}